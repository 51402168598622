import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ff2d299 = () => interopDefault(import('../../client/wineaccess/pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _30ec7433 = () => interopDefault(import('../../client/wineaccess/pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _f065c5fa = () => interopDefault(import('../../client/wineaccess/pages/champagne-club/index.vue' /* webpackChunkName: "pages/champagne-club/index" */))
const _6f3db6fc = () => interopDefault(import('../../client/wineaccess/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _a1d62f1c = () => interopDefault(import('../../client/wineaccess/pages/club/index.vue' /* webpackChunkName: "pages/club/index" */))
const _7e9ce882 = () => interopDefault(import('../../client/wineaccess/pages/collectors/index.vue' /* webpackChunkName: "pages/collectors/index" */))
const _13818700 = () => interopDefault(import('../../client/wineaccess/pages/connoisseurs-wine-club/index.vue' /* webpackChunkName: "pages/connoisseurs-wine-club/index" */))
const _266beca4 = () => interopDefault(import('../../client/wineaccess/pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _51c795a1 = () => interopDefault(import('../../client/wineaccess/pages/corporate-gifting.vue' /* webpackChunkName: "pages/corporate-gifting" */))
const _6540c364 = () => interopDefault(import('../../client/wineaccess/pages/decanter/index.vue' /* webpackChunkName: "pages/decanter/index" */))
const _6df538b7 = () => interopDefault(import('../../client/wineaccess/pages/discovery-wine-club/index.vue' /* webpackChunkName: "pages/discovery-wine-club/index" */))
const _0582ef30 = () => interopDefault(import('../../client/wineaccess/pages/diversity.vue' /* webpackChunkName: "pages/diversity" */))
const _12f57e98 = () => interopDefault(import('../../client/wineaccess/pages/en-primeur/index.vue' /* webpackChunkName: "pages/en-primeur/index" */))
const _423a8b22 = () => interopDefault(import('../../client/wineaccess/pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _f6049a08 = () => interopDefault(import('../../client/wineaccess/pages/giftcard/index.vue' /* webpackChunkName: "pages/giftcard/index" */))
const _5fbeb5c2 = () => interopDefault(import('../../client/wineaccess/pages/invite.vue' /* webpackChunkName: "pages/invite" */))
const _57111dee = () => interopDefault(import('../../client/wineaccess/pages/michelin/index.vue' /* webpackChunkName: "pages/michelin/index" */))
const _8c62f5cc = () => interopDefault(import('../../client/wineaccess/pages/perfect-provenance.vue' /* webpackChunkName: "pages/perfect-provenance" */))
const _344e2d5e = () => interopDefault(import('../../client/wineaccess/pages/podcast/index.vue' /* webpackChunkName: "pages/podcast/index" */))
const _a742ff48 = () => interopDefault(import('../../client/wineaccess/pages/rimowa.vue' /* webpackChunkName: "pages/rimowa" */))
const _4f6e9bdc = () => interopDefault(import('../../client/wineaccess/pages/sunset/index.vue' /* webpackChunkName: "pages/sunset/index" */))
const _284eb0c0 = () => interopDefault(import('../../client/wineaccess/pages/vinovest/index.vue' /* webpackChunkName: "pages/vinovest/index" */))
const _af38cae2 = () => interopDefault(import('../../client/wineaccess/pages/waitlist/index.vue' /* webpackChunkName: "pages/waitlist/index" */))
const _1fd4bc0f = () => interopDefault(import('../../client/wineaccess/pages/wheelsup/index.vue' /* webpackChunkName: "pages/wheelsup/index" */))
const _4a66a168 = () => interopDefault(import('../../client/wineaccess/pages/where-we-ship.vue' /* webpackChunkName: "pages/where-we-ship" */))
const _75fd67ae = () => interopDefault(import('../../client/wineaccess/pages/wine-for-normal-people/index.vue' /* webpackChunkName: "pages/wine-for-normal-people/index" */))
const _e2500910 = () => interopDefault(import('../../client/wineaccess/pages/wine-team.vue' /* webpackChunkName: "pages/wine-team" */))
const _3f8b7e84 = () => interopDefault(import('../../client/wineaccess/pages/wset.vue' /* webpackChunkName: "pages/wset" */))
const _f0f03a16 = () => interopDefault(import('../../client/wineaccess/pages/accounts/addresses/index.vue' /* webpackChunkName: "pages/accounts/addresses/index" */))
const _5b219c2a = () => interopDefault(import('../../client/wineaccess/pages/accounts/change-password/index.vue' /* webpackChunkName: "pages/accounts/change-password/index" */))
const _2bf793ed = () => interopDefault(import('../../client/wineaccess/pages/accounts/credits/index.vue' /* webpackChunkName: "pages/accounts/credits/index" */))
const _152678ec = () => interopDefault(import('../../client/wineaccess/pages/accounts/email.vue' /* webpackChunkName: "pages/accounts/email" */))
const _61604808 = () => interopDefault(import('../../client/wineaccess/pages/accounts/orders/index.vue' /* webpackChunkName: "pages/accounts/orders/index" */))
const _0ede49e8 = () => interopDefault(import('../../client/wineaccess/pages/accounts/payment_methods/index.vue' /* webpackChunkName: "pages/accounts/payment_methods/index" */))
const _28d6a23c = () => interopDefault(import('../../client/wineaccess/pages/accounts/profile/index.vue' /* webpackChunkName: "pages/accounts/profile/index" */))
const _7f2f68be = () => interopDefault(import('../../client/wineaccess/pages/accounts/unsubscribe.vue' /* webpackChunkName: "pages/accounts/unsubscribe" */))
const _237cd918 = () => interopDefault(import('../../client/wineaccess/pages/basket/quick-add/index.vue' /* webpackChunkName: "pages/basket/quick-add/index" */))
const _294b4b33 = () => interopDefault(import('../../client/wineaccess/pages/basket/quick-multiple/index.vue' /* webpackChunkName: "pages/basket/quick-multiple/index" */))
const _6a3250f8 = () => interopDefault(import('../../client/wineaccess/pages/cellar/saved.vue' /* webpackChunkName: "pages/cellar/saved" */))
const _67dcd668 = () => interopDefault(import('../../client/wineaccess/pages/champagne-club/join/index.vue' /* webpackChunkName: "pages/champagne-club/join/index" */))
const _01bfbea6 = () => interopDefault(import('../../client/wineaccess/pages/collectors/join/index.vue' /* webpackChunkName: "pages/collectors/join/index" */))
const _50689b6f = () => interopDefault(import('../../client/wineaccess/pages/connoisseurs-wine-club/join/index.vue' /* webpackChunkName: "pages/connoisseurs-wine-club/join/index" */))
const _5a39ab3e = () => interopDefault(import('../../client/wineaccess/pages/decanter/join/index.vue' /* webpackChunkName: "pages/decanter/join/index" */))
const _762aacd0 = () => interopDefault(import('../../client/wineaccess/pages/discovery-wine-club/join/index.vue' /* webpackChunkName: "pages/discovery-wine-club/join/index" */))
const _6125b4a0 = () => interopDefault(import('../../client/wineaccess/pages/giftcard/payment.vue' /* webpackChunkName: "pages/giftcard/payment" */))
const _2efb7131 = () => interopDefault(import('../../client/wineaccess/pages/giftcard/terms.vue' /* webpackChunkName: "pages/giftcard/terms" */))
const _282516e6 = () => interopDefault(import('../../client/wineaccess/pages/giftcard/thank-you.vue' /* webpackChunkName: "pages/giftcard/thank-you" */))
const _3af8d486 = () => interopDefault(import('../../client/wineaccess/pages/michelin/join/index.vue' /* webpackChunkName: "pages/michelin/join/index" */))
const _9df8f55e = () => interopDefault(import('../../client/wineaccess/pages/podcast/join/index.vue' /* webpackChunkName: "pages/podcast/join/index" */))
const _27b85dc6 = () => interopDefault(import('../../client/wineaccess/pages/sunset/join/index.vue' /* webpackChunkName: "pages/sunset/join/index" */))
const _6f17cf2f = () => interopDefault(import('../../client/wineaccess/pages/vinovest/join/index.vue' /* webpackChunkName: "pages/vinovest/join/index" */))
const _5430a280 = () => interopDefault(import('../../client/wineaccess/pages/waitlist/join/index.vue' /* webpackChunkName: "pages/waitlist/join/index" */))
const _f1210580 = () => interopDefault(import('../../client/wineaccess/pages/wheelsup/join/index.vue' /* webpackChunkName: "pages/wheelsup/join/index" */))
const _13c033f7 = () => interopDefault(import('../../client/wineaccess/pages/wine-club/gifting/index.vue' /* webpackChunkName: "pages/wine-club/gifting/index" */))
const _23faed66 = () => interopDefault(import('../../client/wineaccess/pages/wine-for-normal-people/join/index.vue' /* webpackChunkName: "pages/wine-for-normal-people/join/index" */))
const _d91f4bf8 = () => interopDefault(import('../../client/wineaccess/pages/accounts/addresses/add.vue' /* webpackChunkName: "pages/accounts/addresses/add" */))
const _96df880e = () => interopDefault(import('../../client/wineaccess/pages/accounts/orders/gifts.vue' /* webpackChunkName: "pages/accounts/orders/gifts" */))
const _7db66bdb = () => interopDefault(import('../../client/wineaccess/pages/accounts/payment_methods/add.vue' /* webpackChunkName: "pages/accounts/payment_methods/add" */))
const _43ab6ce0 = () => interopDefault(import('../../client/wineaccess/pages/accounts/profile/edit.vue' /* webpackChunkName: "pages/accounts/profile/edit" */))
const _5af22156 = () => interopDefault(import('../../client/wineaccess/pages/accounts/orders/weather/_id.vue' /* webpackChunkName: "pages/accounts/orders/weather/_id" */))
const _2dc0c860 = () => interopDefault(import('../../client/wineaccess/pages/accounts/addresses/_addressId/index.vue' /* webpackChunkName: "pages/accounts/addresses/_addressId/index" */))
const _12bf75fc = () => interopDefault(import('../../client/wineaccess/pages/cellar/wines/_slug.vue' /* webpackChunkName: "pages/cellar/wines/_slug" */))
const _120992af = () => interopDefault(import('../../client/wineaccess/pages/wine-club/gifting/_slug.vue' /* webpackChunkName: "pages/wine-club/gifting/_slug" */))
const _6cc4f8e6 = () => interopDefault(import('../../client/wineaccess/pages/accounts/addresses/_addressId/delete.vue' /* webpackChunkName: "pages/accounts/addresses/_addressId/delete" */))
const _d4b0df96 = () => interopDefault(import('../../client/wineaccess/pages/accounts/payment_methods/_methodId/delete.vue' /* webpackChunkName: "pages/accounts/payment_methods/_methodId/delete" */))
const _1ad68453 = () => interopDefault(import('../../client/wineaccess/pages/accounts/payment_methods/_methodId/update.vue' /* webpackChunkName: "pages/accounts/payment_methods/_methodId/update" */))
const _f924de5e = () => interopDefault(import('../../client/wineaccess/pages/accounts/orders/_detail/_id.vue' /* webpackChunkName: "pages/accounts/orders/_detail/_id" */))
const _211badb4 = () => interopDefault(import('../../client/wineaccess/pages/checkout/add-to-order/_detail/_id.vue' /* webpackChunkName: "pages/checkout/add-to-order/_detail/_id" */))
const _999585a4 = () => interopDefault(import('../../client/wineaccess/pages/catalog/_slug/index.vue' /* webpackChunkName: "pages/catalog/_slug/index" */))
const _69647261 = () => interopDefault(import('../../client/wineaccess/pages/confirm-email/_slug.vue' /* webpackChunkName: "pages/confirm-email/_slug" */))
const _2d046356 = () => interopDefault(import('../../client/wineaccess/pages/redeem-gift/_code.vue' /* webpackChunkName: "pages/redeem-gift/_code" */))
const _5afc0e79 = () => interopDefault(import('../../client/wineaccess/pages/catalog/_category/_slug.vue' /* webpackChunkName: "pages/catalog/_category/_slug" */))
const _e0ee71fe = () => interopDefault(import('../../client/wineaccess/pages/store/_category/_slug.vue' /* webpackChunkName: "pages/store/_category/_slug" */))
const _443389a9 = () => interopDefault(import('../../client/wineaccess/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _302e97cf = () => interopDefault(import('../../client/wineaccess/pages/_slug_wagtail.vue' /* webpackChunkName: "pages/_slug_wagtail" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart/",
    component: _7ff2d299,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/catalog/",
    component: _30ec7433,
    pathToRegexpOptions: {"strict":true},
    name: "catalog"
  }, {
    path: "/champagne-club/",
    component: _f065c5fa,
    pathToRegexpOptions: {"strict":true},
    name: "champagne-club"
  }, {
    path: "/checkout/",
    component: _6f3db6fc,
    pathToRegexpOptions: {"strict":true},
    name: "checkout"
  }, {
    path: "/club/",
    component: _a1d62f1c,
    pathToRegexpOptions: {"strict":true},
    name: "club"
  }, {
    path: "/collectors/",
    component: _7e9ce882,
    pathToRegexpOptions: {"strict":true},
    name: "collectors"
  }, {
    path: "/connoisseurs-wine-club/",
    component: _13818700,
    pathToRegexpOptions: {"strict":true},
    name: "connoisseurs-wine-club"
  }, {
    path: "/contact-us/",
    component: _266beca4,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us"
  }, {
    path: "/corporate-gifting/",
    component: _51c795a1,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-gifting"
  }, {
    path: "/decanter/",
    component: _6540c364,
    pathToRegexpOptions: {"strict":true},
    name: "decanter"
  }, {
    path: "/discovery-wine-club/",
    component: _6df538b7,
    pathToRegexpOptions: {"strict":true},
    name: "discovery-wine-club"
  }, {
    path: "/diversity/",
    component: _0582ef30,
    pathToRegexpOptions: {"strict":true},
    name: "diversity"
  }, {
    path: "/en-primeur/",
    component: _12f57e98,
    pathToRegexpOptions: {"strict":true},
    name: "en-primeur"
  }, {
    path: "/error/",
    component: _423a8b22,
    pathToRegexpOptions: {"strict":true},
    name: "error"
  }, {
    path: "/giftcard/",
    component: _f6049a08,
    pathToRegexpOptions: {"strict":true},
    name: "giftcard"
  }, {
    path: "/invite/",
    component: _5fbeb5c2,
    pathToRegexpOptions: {"strict":true},
    name: "invite"
  }, {
    path: "/michelin/",
    component: _57111dee,
    pathToRegexpOptions: {"strict":true},
    name: "michelin"
  }, {
    path: "/perfect-provenance/",
    component: _8c62f5cc,
    pathToRegexpOptions: {"strict":true},
    name: "perfect-provenance"
  }, {
    path: "/podcast/",
    component: _344e2d5e,
    pathToRegexpOptions: {"strict":true},
    name: "podcast"
  }, {
    path: "/rimowa/",
    component: _a742ff48,
    pathToRegexpOptions: {"strict":true},
    name: "rimowa"
  }, {
    path: "/sunset/",
    component: _4f6e9bdc,
    pathToRegexpOptions: {"strict":true},
    name: "sunset"
  }, {
    path: "/vinovest/",
    component: _284eb0c0,
    pathToRegexpOptions: {"strict":true},
    name: "vinovest"
  }, {
    path: "/waitlist/",
    component: _af38cae2,
    pathToRegexpOptions: {"strict":true},
    name: "waitlist"
  }, {
    path: "/wheelsup/",
    component: _1fd4bc0f,
    pathToRegexpOptions: {"strict":true},
    name: "wheelsup"
  }, {
    path: "/where-we-ship/",
    component: _4a66a168,
    pathToRegexpOptions: {"strict":true},
    name: "where-we-ship"
  }, {
    path: "/wine-for-normal-people/",
    component: _75fd67ae,
    pathToRegexpOptions: {"strict":true},
    name: "wine-for-normal-people"
  }, {
    path: "/wine-team/",
    component: _e2500910,
    pathToRegexpOptions: {"strict":true},
    name: "wine-team"
  }, {
    path: "/wset/",
    component: _3f8b7e84,
    pathToRegexpOptions: {"strict":true},
    name: "wset"
  }, {
    path: "/accounts/addresses/",
    component: _f0f03a16,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-addresses"
  }, {
    path: "/accounts/change-password/",
    component: _5b219c2a,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-change-password"
  }, {
    path: "/accounts/credits/",
    component: _2bf793ed,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-credits"
  }, {
    path: "/accounts/email/",
    component: _152678ec,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-email"
  }, {
    path: "/accounts/orders/",
    component: _61604808,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-orders"
  }, {
    path: "/accounts/payment_methods/",
    component: _0ede49e8,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-payment_methods"
  }, {
    path: "/accounts/profile/",
    component: _28d6a23c,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-profile"
  }, {
    path: "/accounts/unsubscribe/",
    component: _7f2f68be,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-unsubscribe"
  }, {
    path: "/basket/quick-add/",
    component: _237cd918,
    pathToRegexpOptions: {"strict":true},
    name: "basket-quick-add"
  }, {
    path: "/basket/quick-multiple/",
    component: _294b4b33,
    pathToRegexpOptions: {"strict":true},
    name: "basket-quick-multiple"
  }, {
    path: "/cellar/saved/",
    component: _6a3250f8,
    pathToRegexpOptions: {"strict":true},
    name: "cellar-saved"
  }, {
    path: "/champagne-club/join/",
    component: _67dcd668,
    pathToRegexpOptions: {"strict":true},
    name: "champagne-club-join"
  }, {
    path: "/collectors/join/",
    component: _01bfbea6,
    pathToRegexpOptions: {"strict":true},
    name: "collectors-join"
  }, {
    path: "/connoisseurs-wine-club/join/",
    component: _50689b6f,
    pathToRegexpOptions: {"strict":true},
    name: "connoisseurs-wine-club-join"
  }, {
    path: "/decanter/join/",
    component: _5a39ab3e,
    pathToRegexpOptions: {"strict":true},
    name: "decanter-join"
  }, {
    path: "/discovery-wine-club/join/",
    component: _762aacd0,
    pathToRegexpOptions: {"strict":true},
    name: "discovery-wine-club-join"
  }, {
    path: "/giftcard/payment/",
    component: _6125b4a0,
    pathToRegexpOptions: {"strict":true},
    name: "giftcard-payment"
  }, {
    path: "/giftcard/terms/",
    component: _2efb7131,
    pathToRegexpOptions: {"strict":true},
    name: "giftcard-terms"
  }, {
    path: "/giftcard/thank-you/",
    component: _282516e6,
    pathToRegexpOptions: {"strict":true},
    name: "giftcard-thank-you"
  }, {
    path: "/michelin/join/",
    component: _3af8d486,
    pathToRegexpOptions: {"strict":true},
    name: "michelin-join"
  }, {
    path: "/podcast/join/",
    component: _9df8f55e,
    pathToRegexpOptions: {"strict":true},
    name: "podcast-join"
  }, {
    path: "/sunset/join/",
    component: _27b85dc6,
    pathToRegexpOptions: {"strict":true},
    name: "sunset-join"
  }, {
    path: "/vinovest/join/",
    component: _6f17cf2f,
    pathToRegexpOptions: {"strict":true},
    name: "vinovest-join"
  }, {
    path: "/waitlist/join/",
    component: _5430a280,
    pathToRegexpOptions: {"strict":true},
    name: "waitlist-join"
  }, {
    path: "/wheelsup/join/",
    component: _f1210580,
    pathToRegexpOptions: {"strict":true},
    name: "wheelsup-join"
  }, {
    path: "/wine-club/gifting/",
    component: _13c033f7,
    pathToRegexpOptions: {"strict":true},
    name: "wine-club-gifting"
  }, {
    path: "/wine-for-normal-people/join/",
    component: _23faed66,
    pathToRegexpOptions: {"strict":true},
    name: "wine-for-normal-people-join"
  }, {
    path: "/accounts/addresses/add/",
    component: _d91f4bf8,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-addresses-add"
  }, {
    path: "/accounts/orders/gifts/",
    component: _96df880e,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-orders-gifts"
  }, {
    path: "/accounts/payment_methods/add/",
    component: _7db66bdb,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-payment_methods-add"
  }, {
    path: "/accounts/profile/edit/",
    component: _43ab6ce0,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-profile-edit"
  }, {
    path: "/accounts/orders/weather/:id?/",
    component: _5af22156,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-orders-weather-id"
  }, {
    path: "/accounts/addresses/:addressId/",
    component: _2dc0c860,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-addresses-addressId"
  }, {
    path: "/cellar/wines/:slug?/",
    component: _12bf75fc,
    pathToRegexpOptions: {"strict":true},
    name: "cellar-wines-slug"
  }, {
    path: "/wine-club/gifting/:slug?/",
    component: _120992af,
    pathToRegexpOptions: {"strict":true},
    name: "wine-club-gifting-slug"
  }, {
    path: "/accounts/addresses/:addressId/delete/",
    component: _6cc4f8e6,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-addresses-addressId-delete"
  }, {
    path: "/accounts/payment_methods/:methodId/delete/",
    component: _d4b0df96,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-payment_methods-methodId-delete"
  }, {
    path: "/accounts/payment_methods/:methodId/update/",
    component: _1ad68453,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-payment_methods-methodId-update"
  }, {
    path: "/accounts/orders/:detail/:id?/",
    component: _f924de5e,
    pathToRegexpOptions: {"strict":true},
    name: "accounts-orders-detail-id"
  }, {
    path: "/checkout/add-to-order/:detail?/:id?/",
    component: _211badb4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add-to-order-detail-id"
  }, {
    path: "/catalog/:slug/",
    component: _999585a4,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug"
  }, {
    path: "/confirm-email/:slug?/",
    component: _69647261,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-email-slug"
  }, {
    path: "/redeem-gift/:code?/",
    component: _2d046356,
    pathToRegexpOptions: {"strict":true},
    name: "redeem-gift-code"
  }, {
    path: "/catalog/:category/:slug?/",
    component: _5afc0e79,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-category-slug"
  }, {
    path: "/store/:category?/:slug?/",
    component: _e0ee71fe,
    alias: ["/store/","/store/search/"],
    pathToRegexpOptions: {"strict":true},
    name: "store-category-slug"
  }, {
    path: "/",
    component: _443389a9,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:slug_wagtail/",
    component: _302e97cf,
    pathToRegexpOptions: {"strict":true},
    name: "slug_wagtail"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
